import styled, { keyframes } from 'styled-components';

const fade = keyframes`
  0% {
    opacity: 0;
    transform: translateX(64px);
  } 50% {
    transform: translateX(64px);
    opacity: 1;
  } 100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const fadeText = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-64px);
  } 50% {
    transform: translateX(-64px);
  } 70% {
    opacity: 0;
  } 100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

export const MainSpinnerContainer = styled.div`
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	background: #161e24;
	z-index: 5000;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;

	.spinnerDescription {
		display: flex;
		justify-content: center;
		flex-direction: column;
	}

	p {
		font-family: 'Nunito';
		margin: 20px 20px 0 20px;
		text-align: center;
		color: #a9a0ae;
		align-items: center;
	}

	.logoContainer {
		display: flex;
		margin: 0 auto;
		align-items: center;
	}

	.ebbiner {
		margin: 0 12.8px 0 0;
		animation-duration: 1.5s;
		animation-timing-function: cubic-bezier(0.1, 0.5, 0.8 0.1);
		animation-name: ${fade};
		transform: translateX(0);
		animation-iteration-count: 1, 2, infinite;
	}

	.ebbinerText {
		animation-duration: 1.5s;
		animation-timing-function: cubic-bezier(0.1, 0.5, 0.8 0.1);
		animation-name: ${fadeText};
		transform: translateX(0);
		animation-iteration-count: 1, 2, infinite;
	}
`;
